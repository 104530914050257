import * as React from "react"
import { Text, ThemeCss } from "gatsby-interface"
import { siteDetails as siteDetailsText } from "@modules/locales/default.js"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { interpolateMessage } from "@modules/locales"
import { MdFolderOpen } from "react-icons/md"

const textCss: ThemeCss = theme => ({
  color: theme.colors.grey[50],
  margin: `0`,
  display: `flex`,
  alignItems: `center`,
})

const iconCss: ThemeCss = theme => ({
  color: theme.colors.grey[40],
})

const spanCss: ThemeCss = theme => {
  return { paddingLeft: theme.space[2] }
}

export type SiteDirectoryPathProps = {
  path: string
}

export function SiteDirectoryPath({ path }: SiteDirectoryPathProps) {
  return (
    <Text
      size="S"
      css={textCss}
      data-testid="information-view-site-directory-path"
    >
      <MdFolderOpen aria-hidden css={iconCss} />
      <span css={spanCss} aria-hidden>
        {path}
      </span>
      <span css={visuallyHiddenCss}>
        {interpolateMessage<"directoryPath">(
          siteDetailsText.labels.siteDirectory,
          { directoryPath: path }
        )}
      </span>
    </Text>
  )
}
