import React from "react"
import PropTypes from "prop-types"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import { OrganizationStatus, PreviewStatus } from "@modules/graphql/types"
import { useTracker } from "@modules/analytics"
import { PREVIEW_STATUS_VARIANTS } from "../preview-status"
import { Text } from "gatsby-interface"

const propTypes = {
  status: PropTypes.oneOfType(Object.values(PreviewStatus)),
  orgStatus: PropTypes.oneOfType(Object.values(OrganizationStatus)),
  url: PropTypes.string.isRequired,
}

const textCss = theme => ({
  color: theme.colors.grey[90],
  fontWeight: "bold",
  margin: 0,
})

export function PreviewTitle({
  orgStatus,
  status,
  url,
  siteId,
  incrementalPreviewsEnabled,
}) {
  const { trackButtonClicked } = useTracker()

  if (orgStatus === OrganizationStatus.Idle) {
    return (
      <Text data-testid="previews-idle" size="L" css={textCss}>
        {PREVIEW_STATUS_VARIANTS.IDLE.note}
      </Text>
    )
  }

  if (status === PreviewStatus.Ready) {
    return (
      <ExternalLink
        data-testid="preview-ready"
        href={url}
        omitProtocol={true}
        truncate={true}
        onClick={() =>
          trackButtonClicked(`Site Preview URL`, {
            siteId,
            uiSource: `Site previews`,
          })
        }
      >
        {url}
      </ExternalLink>
    )
  }

  return (
    <React.Fragment>
      {incrementalPreviewsEnabled && (
        <ExternalLink
          data-testid="preview-ready"
          href={url}
          omitProtocol={true}
          truncate={true}
          onClick={() =>
            trackButtonClicked(`Site Preview URL`, {
              siteId,
              uiSource: `Site previews`,
            })
          }
          css={{ marginBottom: "8px" }}
        >
          {url}
        </ExternalLink>
      )}
      <Text data-testid="preview-building" size="L" css={textCss}>
        {PREVIEW_STATUS_VARIANTS[status]?.note}
      </Text>
    </React.Fragment>
  )
}
PreviewTitle.propTypes = propTypes
