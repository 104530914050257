import React from "react"
import { Location } from "@gatsbyjs/reach-router"
import { getPathToBuildDetails } from "@modules/site/details/utils"
import ForwardLink from "@modules/ui/components/ForwardLink"

const BuildDetailsLink = React.forwardRef(function BuildDetailsLink(
  {
    id,
    siteId,
    organizationId,
    variant = `SECONDARY`,
    children = `View Details`,
  },
  ref
) {
  return (
    <Location>
      {({ location }) => {
        let pathToDetails = getPathToBuildDetails(id, siteId, organizationId)
        const returnTo = location.pathname || ``
        if (returnTo) {
          pathToDetails = `${pathToDetails}?returnTo=${encodeURIComponent(
            returnTo
          )}`
        }

        return (
          <ForwardLink
            aria-label="Build Details"
            to={pathToDetails}
            variant={variant}
            ref={ref}
          >
            {children}
          </ForwardLink>
        )
      }}
    </Location>
  )
})

export default BuildDetailsLink
