/* tslint:disable:@typescript-eslint/no-non-null-assertion */
import * as React from "react"
import { WindowLocation } from "@gatsbyjs/reach-router"
import { Deploys } from "./Deploys"

export type DeploysPaneProps = {
  organizationId: string
  siteId: string
  location?: WindowLocation
}

export function DeploysPane({
  organizationId,
  siteId,
  location,
}: DeploysPaneProps) {
  return (
    <Deploys
      organizationId={organizationId}
      siteId={siteId}
      location={location}
    />
  )
}
