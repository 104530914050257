/* tslint:disable:@typescript-eslint/no-non-null-assertion */
import * as React from "react"
import { WindowLocation } from "@gatsbyjs/reach-router"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { useLocalSiteDetailsForBuilds } from "@modules/site/shared/hooks/useLocalSiteDetails"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"
import useSiteDetailsData from "@modules/site/shared/hooks/useSiteDetailsData"
import { useLocation } from "@gatsbyjs/reach-router"
import * as qs from "query-string"
import { SiteBuildsProduction } from "./SiteBuildsProduction"
import { useRollbacksAndManualDeploysAvailable } from "@modules/organization/shared/hooks/useRollbacksAndManualDeploysAvailable"

export type DeploysProps = {
  organizationId: string
  siteId: string
  location?: WindowLocation
  onViewBuildQueueClick?: () => void
}

export function Deploys({
  organizationId,
  siteId,
  onViewBuildQueueClick,
}: DeploysProps) {
  const { search } = useLocation()
  const { newsite: isNewSite } = qs.parse(search)

  const siteDetails = useLocalSiteDetailsForBuilds(siteId)
  const repositoryUrl = siteDetails.repository?.url
  const isEligiblePlan =
    useRollbacksAndManualDeploysAvailable(organizationId).result

  const lastBuildForBranch = siteDetails?.latestBuild

  useSiteChangedSubscription({
    variables: { id: siteId },
  })

  const [, { startPolling, stopPolling, refetch: refetchSiteDetails }] =
    useSiteDetailsData(siteId, {
      shouldSkip: !isNewSite,
    })

  if (isNewSite) {
    startPolling(3000)
  }

  if (siteDetails?.stableBuildURL) {
    stopPolling()
  }

  return (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        <SiteBuildsProduction
          organizationId={organizationId}
          siteId={siteId}
          siteData={siteDetails}
          lastBuild={lastBuildForBranch}
          repositoryUrl={repositoryUrl}
          isEligiblePlan={isEligiblePlan}
          refetchSiteDetails={refetchSiteDetails}
          onViewBuildQueue={onViewBuildQueueClick}
        />
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  )
}
