import * as React from "react"
import { ThemeCss, ProgressBar, Tooltip, Theme } from "gatsby-interface"
import {
  PlatformLimitSummary,
  PlatformUsage,
  PlatformLimitSummaryName,
} from "@modules/graphql/types"
import { MdReportProblem } from "react-icons/md"
import {
  PlatformLimitLabels,
  PlatformLimitsOrder,
} from "@modules/organization/shared/constants"

const OVERAGE_WARNING_THRESHOLD = 75

const gridCss: ThemeCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `auto auto`,
  columnGap: theme.space[8],
})

const colCss: ThemeCss = theme => ({
  display: `grid`,
  alignContent: `start`,
})

const labelCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
  fontWeight: theme.fontWeights.body,
  lineHeight: theme.lineHeights.solid,
})

const descriptionCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
})

const progressBarCss: ThemeCss = theme => ({
  marginTop: `0.4rem`, // manually set value to align with sibling widgets,
  marginBottom: theme.space[2],

  [theme.mediaQueries.desktop]: {
    width: `100px`,
  },
})

const tooltipCss: ThemeCss = theme => ({
  paddingBottom: theme.space[1],
  fontSize: theme.fontSizes[1],

  p: {
    margin: 0,
    whiteSpace: `nowrap`,
  },

  svg: {
    color: theme.colors.orange[60],
    verticalAlign: `middle`,
  },
})

type TooltipContentProps = {
  name: string
  workspace?: PlatformUsage | null
  site?: PlatformUsage | null
}

function TooltipContent({ name, workspace, site }: TooltipContentProps) {
  if (!workspace || !site) {
    return null
  }

  return (
    <div css={tooltipCss}>
      <p>
        This site:{" "}
        {site.percentageOfLimit >= OVERAGE_WARNING_THRESHOLD && (
          <MdReportProblem />
        )}{" "}
        <strong>{site.formattedCount}</strong>{" "}
        {PlatformLimitLabels[name as PlatformLimitSummaryName]}
      </p>
      <p>
        This workspace:{" "}
        {workspace.percentageOfLimit >= OVERAGE_WARNING_THRESHOLD && (
          <MdReportProblem />
        )}{" "}
        <strong>{workspace.formattedCount}</strong>{" "}
        {PlatformLimitLabels[name as PlatformLimitSummaryName]}
      </p>
    </div>
  )
}

const getProgressColor = (theme: Theme, progression: number) => {
  if (progression >= OVERAGE_WARNING_THRESHOLD) {
    return theme.colors.orange[60]
  }

  return theme.colors.purple[60]
}

const getSecondProgressColor = (theme: Theme, progression: number) => {
  if (progression >= OVERAGE_WARNING_THRESHOLD) {
    return theme.colors.orange[40]
  }

  return theme.colors.grey[30]
}

export type SiteHostingLimitsInfoProps = {
  platformLimitSummaries: PlatformLimitSummary[]
}

export function SiteHostingLimitsInfo({
  platformLimitSummaries = [],
}: SiteHostingLimitsInfoProps) {
  const data = platformLimitSummaries
    .sort(function (x, y) {
      return (
        PlatformLimitsOrder.findIndex(
          item =>
            item === PlatformLimitLabels[x.name as PlatformLimitSummaryName]
        ) -
        PlatformLimitsOrder.findIndex(
          item =>
            item === PlatformLimitLabels[y.name as PlatformLimitSummaryName]
        )
      )
    })
    .filter(item => {
      return item.limit !== -1
    })

  return data.length > 0 ? (
    <div
      css={(theme: Theme) => [
        gridCss(theme),
        {
          gridTemplateColumns: `repeat(${data.length}, 1fr)`,
        },
      ]}
    >
      {data.map(({ name, workspace, site, formattedLimit }) => {
        if (!site) {
          return null
        }

        const label = PlatformLimitLabels[name as PlatformLimitSummaryName]

        if (!label) {
          return null
        }

        const formattedLabel = `${label.charAt(0).toUpperCase()}${label.slice(
          1
        )}`

        return (
          <Tooltip
            key={name}
            label={
              <TooltipContent workspace={workspace} site={site} name={name} />
            }
          >
            <div css={colCss} key={name}>
              <span css={labelCss}>{formattedLabel}</span>
              <div css={progressBarCss}>
                <ProgressBar
                  value={site?.percentageOfLimit || 0}
                  secondValue={workspace?.percentageOfLimit || 0}
                  max={100}
                  aria-describedby={name}
                  height={8}
                  getProgressColor={getProgressColor}
                  getSecondProgressColor={getSecondProgressColor}
                />
              </div>
              <span
                id={name}
                css={descriptionCss}
              >{`${site?.formattedCount} / ${formattedLimit}`}</span>
            </div>
          </Tooltip>
        )
      })}
    </div>
  ) : null
}
