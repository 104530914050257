import * as React from "react"
import { useBuildByIdQuery } from "@modules/build/shared/queries.generated"
import { BuildCard } from "@modules/build/card/components/BuildCard"
import { Site } from "@modules/graphql/types"
import { getPathToBuildDetails } from "@modules/site/details/utils"
import { rootCss } from "@modules/site/shared/stylesheets/siteBuildsBuildSection"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"

export type SiteBuildsPublishedBuildProps = {
  buildId?: string
  organizationId: string
  siteId: string
  siteData: Site
  gatsbyHostingOn: boolean
  repositoryUrl: string
  isEligiblePlan: boolean
}

export function SiteBuildsPublishedBuild({
  buildId,
  siteId,
  organizationId,
  siteData,
  gatsbyHostingOn,
  isEligiblePlan,
  repositoryUrl,
}: SiteBuildsPublishedBuildProps) {
  const { data, error } = useBuildByIdQuery({
    variables: {
      buildId,
    },
    fetchPolicy: `cache-and-network`,
    skip: !buildId,
  })
  const buildData = data?.buildById

  if (!buildData) {
    return null
  }

  if (error) {
    return (
      <div css={rootCss}>
        <ErrorAlert>{error.message}</ErrorAlert>
      </div>
    )
  }

  return (
    <div css={rootCss}>
      {buildData && (
        <BuildCard
          id={`builds-production-branch-${buildData?.id}-published`}
          siteId={siteId}
          organizationId={organizationId}
          buildId={buildData.id}
          status={buildData?.buildStatus}
          createdAt={buildData.createdAt}
          startedAt={buildData.startedAt}
          /* backend allows for null. Fallbacking to undefined for safe types in child components */
          duration={buildData.duration || undefined}
          endedAt={buildData.endedAt}
          latestHostingDeployVersion={buildId ?? undefined}
          gatsbyHostingOn={gatsbyHostingOn}
          isEligiblePlan={isEligiblePlan}
          branch={siteData.branch}
          /* backend allows for null. Fallbacking to undefined for safe types in child components */
          commit={buildData.commit || undefined}
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          runnerType={buildData.runnerType!}
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          buildType={buildData.buildType!}
          /* backend allows for null. Fallbacking to undefined for safe types in child components */
          source={buildData.source || undefined}
          /* backend allows for null. Fallbacking to undefined for safe types in child components */
          author={buildData.author || undefined}
          isProductionBranch={true}
          viewDetailsHref={getPathToBuildDetails(
            buildData?.id,
            siteId,
            organizationId
          )}
          deployStartedAt={buildData?.deployStartedAt}
          deployEndedAt={buildData?.deployEndedAt}
          repositoryUrl={repositoryUrl}
          routeMetadata={buildData?.routeMetadata || undefined}
          buildMetadata={buildData?.metadata || undefined}
          buildUrl={buildData.url}
        />
      )}
    </div>
  )
}
