import { useSiteDependencies } from "./useSiteDependencies"
import * as semver from "semver"

export const useGatsbyVersions = (
  siteId: string,
  dependencies: { [key: string]: string }
) => {
  const { siteDependencies, loading, error } = useSiteDependencies(siteId)

  const hasDeps = siteDependencies && siteDependencies.length > 0

  if (error || loading || !hasDeps) {
    return [null, { loading, error }]
  }

  const toUpdate = siteDependencies
    .filter(
      ({ name, installedVersion }) =>
        name in dependencies &&
        !semver.satisfies(
          semver.coerce(installedVersion) || ``,
          dependencies[name]
        )
    )
    .map(({ name }) => {
      if (name in dependencies) {
        return `${name}@${dependencies[name].replace(">=", "^")}`
      }
      return null
    })
    .filter(dependencies => dependencies !== null)

  return [toUpdate, { loading, error }]
}
