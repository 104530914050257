import React from "react"
import { Router, Redirect } from "@gatsbyjs/reach-router"
import { DeploysPane } from "@modules/site/details/components/DeploysPane"
import { PullRequests } from "@modules/site/details/components/PullRequests"
import SiteSettingsPane from "@modules/site/details/components/SettingsPane"
import { PreviewsView } from "@modules/site/buildsView/PreviewsView"
import { getPathToSiteDetailsTab } from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"

export function TabRouter({
  siteId,
  organizationId,
  tabs,
  onViewBuildQueueClick,
}) {
  const siteTabEntries = new Map([
    [SiteDetailsTab.Settings, SiteSettingsPane],
    [SiteDetailsTab.Deploys, DeploysPane],
    [SiteDetailsTab.CmsPreview, PreviewsView],
    [SiteDetailsTab.PullRequests, PullRequests],
  ])

  const paneComponentsByTab = Object.fromEntries(siteTabEntries)

  return (
    <Router primary={false}>
      {tabs.map(({ tab, hasSections }) => {
        const Pane = paneComponentsByTab[tab]
        if (!Pane) return null

        return (
          <Pane
            key={tab}
            path={hasSections ? `${tab}/:section` : `${tab}/*`}
            onViewBuildQueueClick={onViewBuildQueueClick}
          />
        )
      })}
      {tabs
        .filter(
          ({ hasSections, initialSection }) => hasSections && initialSection
        )
        .map(({ tab, initialSection }) => (
          <Redirect
            key={`${tab}/${initialSection}`}
            from={`${tab}/`}
            to={getPathToSiteDetailsTab(tab, siteId, organizationId)}
          />
        ))}
      {tabs.length > 0 && (
        <Redirect
          from={`/`}
          to={getPathToSiteDetailsTab(tabs[0].tab, siteId, organizationId)}
          noThrow
        />
      )}
    </Router>
  )
}
