import { useApolloFragment } from "apollo-fragment-react"

const orgNameFragment = `
    fragment OrgNameFragment on Organization {
        name
        billing {
          id
          plan {
            id
            buildsTier
            hostingTier
            baseFeatures {
              tier
            }
          }
        }
    }
`

export default function useOrganizationName(organizationId) {
  const { data } = useApolloFragment(orgNameFragment, organizationId)

  return {
    orgName: data?.name,
    buildsTier: data?.billing?.plan?.buildsTier,
    hostingTier: data?.billing?.plan?.hostingTier,
    tier: data?.billing?.plan?.baseFeatures?.tier,
  }
}
