import * as React from "react"

export function EmptyStateGraphicAlternative(
  props: React.ComponentPropsWithoutRef<"svg">
) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="24" cy="30" r="16" fill="#DBDBDD" />
      <circle
        cx="24"
        cy="5"
        r="2.4"
        fill="white"
        stroke="#B4B3B7"
        strokeWidth="1.2"
      />
      <g opacity="0.5">
        <mask id="path-3-inside-1_259_2730" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7753 4.7002C25.6325 5.55151 24.8921 6.2002 24.0002 6.2002C23.1083 6.2002 22.3679 5.55151 22.2251 4.7002C22.2087 4.79776 22.2002 4.89798 22.2002 5.0002C22.2002 5.99431 23.0061 6.8002 24.0002 6.8002C24.9943 6.8002 25.8002 5.99431 25.8002 5.0002C25.8002 4.89798 25.7917 4.79776 25.7753 4.7002Z"
          />
        </mask>
        <path
          d="M25.7753 4.7002L26.9588 4.50165L24.5918 4.50165L25.7753 4.7002ZM22.2251 4.7002L23.4085 4.50165H21.0416L22.2251 4.7002ZM24.0002 7.4002C25.4879 7.4002 26.7206 6.31851 26.9588 4.89874L24.5918 4.50165C24.5444 4.78451 24.2963 5.0002 24.0002 5.0002V7.4002ZM21.0416 4.89874C21.2798 6.31851 22.5125 7.4002 24.0002 7.4002V5.0002C23.7041 5.0002 23.456 4.78451 23.4085 4.50165L21.0416 4.89874ZM21.0416 4.50165C21.0143 4.66455 21.0002 4.83119 21.0002 5.0002H23.4002C23.4002 4.96478 23.4031 4.93096 23.4085 4.89874L21.0416 4.50165ZM21.0002 5.0002C21.0002 6.65705 22.3433 8.0002 24.0002 8.0002V5.6002C23.6688 5.6002 23.4002 5.33157 23.4002 5.0002H21.0002ZM24.0002 8.0002C25.657 8.0002 27.0002 6.65705 27.0002 5.0002H24.6002C24.6002 5.33157 24.3316 5.6002 24.0002 5.6002V8.0002ZM27.0002 5.0002C27.0002 4.83119 26.9861 4.66455 26.9588 4.50165L24.5918 4.89874C24.5973 4.93096 24.6002 4.96478 24.6002 5.0002H27.0002Z"
          fill="#B4B3B7"
          mask="url(#path-3-inside-1_259_2730)"
        />
      </g>
      <line
        x1="24.0004"
        y1="7"
        x2="24.0004"
        y2="10"
        stroke="#B4B3B7"
        strokeWidth="1.2"
      />
      <mask
        id="mask0_259_2730"
        style={{ maskType: `alpha` }}
        maskUnits="userSpaceOnUse"
        x="9"
        y="15"
        width="30"
        height="30"
      >
        <circle
          cx="24.0002"
          cy="30.0002"
          r="14.3"
          fill="#0C0C0E"
          stroke="#0C0C0E"
        />
      </mask>
      <g mask="url(#mask0_259_2730)">
        <mask
          id="mask1_259_2730"
          style={{ maskType: `alpha` }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="35"
          width="20"
          height="18"
        >
          <rect
            x="14.7"
            y="35.7"
            width="18.6"
            height="16.6"
            rx="5.3"
            fill="#0C0C0E"
            stroke="#0C0C0E"
            strokeWidth="1.4"
          />
        </mask>
        <g mask="url(#mask1_259_2730)">
          <rect x="14" y="35" width="20" height="18" rx="6" fill="white" />
          <rect
            x="14.7"
            y="35.7"
            width="18.6"
            height="16.6"
            rx="5.3"
            stroke="#B4B3B7"
            strokeWidth="1.4"
          />
        </g>
      </g>
      <rect
        x="7.1"
        y="17.6"
        width="8.8"
        height="10.8"
        rx="3.4"
        fill="white"
        stroke="#B4B3B7"
        strokeWidth="1.2"
      />
      <rect
        x="32.1"
        y="17.6"
        width="8.8"
        height="10.8"
        rx="3.4"
        fill="white"
        stroke="#B4B3B7"
        strokeWidth="1.2"
      />
      <rect
        x="10.2"
        y="10.2"
        width="27.6"
        height="23.1"
        rx="10.3"
        fill="white"
        stroke="#B4B3B7"
        strokeWidth="1.4"
      />
      <rect
        x="13.6"
        y="15.6"
        width="20.8"
        height="13.8"
        rx="5.4"
        fill="white"
        stroke="#B4B3B7"
        strokeWidth="1.2"
      />
      <mask id="path-14-inside-2_259_2730" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0002 16.2002H29.0002C31.6512 16.2002 33.8002 18.3492 33.8002 21.0002V22.5002C33.8002 19.8492 31.6512 17.7002 29.0002 17.7002H19.0002C16.3492 17.7002 14.2002 19.8492 14.2002 22.5002V21.0002C14.2002 18.3492 16.3492 16.2002 19.0002 16.2002Z"
        />
      </mask>
      <path
        d="M29.0002 15.0002H19.0002V17.4002H29.0002V15.0002ZM35.0002 21.0002C35.0002 17.6865 32.3139 15.0002 29.0002 15.0002V17.4002C30.9884 17.4002 32.6002 19.012 32.6002 21.0002H35.0002ZM35.0002 22.5002V21.0002H32.6002V22.5002H35.0002ZM35.0002 22.5002C35.0002 19.1865 32.3139 16.5002 29.0002 16.5002V18.9002C30.9884 18.9002 32.6002 20.512 32.6002 22.5002H35.0002ZM29.0002 16.5002H19.0002V18.9002H29.0002V16.5002ZM19.0002 16.5002C15.6865 16.5002 13.0002 19.1865 13.0002 22.5002H15.4002C15.4002 20.512 17.012 18.9002 19.0002 18.9002V16.5002ZM13.0002 21.0002V22.5002H15.4002V21.0002H13.0002ZM19.0002 15.0002C15.6865 15.0002 13.0002 17.6865 13.0002 21.0002H15.4002C15.4002 19.012 17.012 17.4002 19.0002 17.4002V15.0002Z"
        fill="white"
        mask="url(#path-14-inside-2_259_2730)"
      />
      <rect x="19.5" y="19.5" width="1" height="6" rx="0.5" stroke="#B4B3B7" />
      <rect x="27.5" y="19.5" width="1" height="6" rx="0.5" stroke="#B4B3B7" />
    </svg>
  )
}
