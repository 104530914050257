import * as React from "react"
import { Heading, ThemeCss, Spacer, EmptyState } from "gatsby-interface"
import { Site, Build, CdnVendor } from "@modules/graphql/types"
import { isProperlyConnected } from "@modules/site/cdnIntegrations"
import TriggerBuild from "@modules/build/shared/components/TriggerBuild"
import { deploysView as deploysViewText } from "@modules/locales/default.js"
import { SiteBuildsPublishedBuild } from "./SiteBuildsPublishedBuild"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import { descriptionCss } from "@modules/site/shared/stylesheets/siteBuildsBuildSection"
import { PublishBuildConfirmation } from "@modules/build/shared/components/PublishBuildConfirmation"
import { ProductionBuildsList } from "./ProductionBuilds"
import { EmptyStateGraphicAlternative } from "@modules/ui/components/EmptyStateGraphicAlternative"
import {
  LastSuccessBuildMessage,
  AutomaticDeploysStatus,
} from "./SiteBuildsProduction.parts"

export type SiteBuildsProductionProps = {
  organizationId: string
  siteId: string
  siteData: Site
  lastBuild: Build
  repositoryUrl: string
  isEligiblePlan: boolean
  refetchSiteDetails?: () => void
  onViewBuildQueue?: (id: string) => void
}

export function SiteBuildsProduction({
  siteId,
  organizationId,
  siteData,
  lastBuild,
  repositoryUrl,
  isEligiblePlan,
}: SiteBuildsProductionProps) {
  const [triggerBuildError, setTriggerBuildError] =
    React.useState<JSX.Element | null>(null)
  const [buildToPublish, setBuildToPublish] = React.useState<string | null>(
    null
  )
  const cdnIntegrations = siteData?.cdnIntegrations ?? []
  const latestHostingDeployVersion = siteData?.latestHostingDeployVersion
  const manualHostingDeploysEnabled = siteData?.manualHostingDeploysEnabled
  const activeIntegration = cdnIntegrations.find(isProperlyConnected)
  const gatsbyHostingOn = activeIntegration?.vendor === CdnVendor.CloudCdn
  const productionBranch = siteData.branch
  const canSeePublishedBuilds = gatsbyHostingOn && isEligiblePlan

  return (
    <React.Fragment>
      <div>
        {gatsbyHostingOn && isEligiblePlan && (
          <div css={topCss}>
            <div css={buildMetaCss}>
              <Heading as="h2" css={headingCss}>
                {deploysViewText.headers.lastPublishedBuild}
              </Heading>
              {siteData?.stableBuildURL && (
                <ExternalLink
                  href={siteData?.stableBuildURL}
                  truncate
                  size={2}
                />
              )}
            </div>

            <div css={buildActionsCss}>
              {gatsbyHostingOn && isEligiblePlan && (
                <AutomaticDeploysStatus
                  siteId={siteId}
                  organizationId={organizationId}
                  enabled={!manualHostingDeploysEnabled}
                />
              )}
              <TriggerBuild
                siteId={siteId}
                selectedBranch={productionBranch}
                orgStatus={siteData.organization?.status}
                size={`M`}
                triggerBuildError={triggerBuildError}
                setTriggerBuildError={setTriggerBuildError}
              />
            </div>
          </div>
        )}

        {triggerBuildError && (
          <React.Fragment>
            <Spacer size={7} />
            {triggerBuildError}
          </React.Fragment>
        )}

        {gatsbyHostingOn && isEligiblePlan && (
          <React.Fragment>
            {latestHostingDeployVersion ? (
              <SiteBuildsPublishedBuild
                siteId={siteId}
                siteData={siteData}
                organizationId={organizationId}
                buildId={latestHostingDeployVersion ?? undefined}
                gatsbyHostingOn={gatsbyHostingOn}
                isEligiblePlan={isEligiblePlan}
                repositoryUrl={repositoryUrl}
              />
            ) : (
              <div css={emptyStateWrapperCss}>
                <EmptyState
                  heading={`No published builds yet`}
                  text={`Your first successfully published build will appear here.`}
                  headingAs="h2"
                  graphic={<EmptyStateGraphicAlternative />}
                  variant="BORDERED"
                />
              </div>
            )}
          </React.Fragment>
        )}

        <ProductionBuildsList
          title={
            <div css={buildHistoryTitleWrapperCss}>
              <div
                css={
                  canSeePublishedBuilds
                    ? buildHistoryTitleRowCss
                    : buildHistoryTitleRowNoPublishedBuildsCss
                }
              >
                <Heading as="h2" css={headingCss}>
                  {deploysViewText.headers.buildHistory}
                </Heading>
                {!canSeePublishedBuilds ? (
                  <TriggerBuild
                    siteId={siteId}
                    selectedBranch={productionBranch}
                    orgStatus={siteData.organization?.status}
                    size={`M`}
                    triggerBuildError={triggerBuildError}
                    setTriggerBuildError={setTriggerBuildError}
                  />
                ) : (
                  <React.Fragment>
                    {lastBuild && siteData?.stableBuildURL && (
                      <div css={stableBuildLinkInline}>
                        <LastSuccessBuildMessage
                          buildURL={siteData?.stableBuildURL}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
              {!canSeePublishedBuilds &&
                lastBuild &&
                siteData?.stableBuildURL && (
                  <LastSuccessBuildMessage
                    buildURL={siteData?.stableBuildURL}
                  />
                )}
            </div>
          }
          organizationId={organizationId}
          siteId={siteId}
          branch={productionBranch}
        />
      </div>

      {!!buildToPublish && (
        <PublishBuildConfirmation
          siteId={siteId}
          buildId={buildToPublish}
          onDismiss={() => setBuildToPublish(null)}
          onComplete={() => {
            setBuildToPublish(null)
          }}
          manualHostingDeploysEnabled={manualHostingDeploysEnabled ?? undefined}
        />
      )}
    </React.Fragment>
  )
}

/* styles */

const emptyStateWrapperCss: ThemeCss = theme => ({
  marginTop: theme.space[6],
  marginBottom: theme.space[10],
})

const buildHistoryTitleWrapperCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  gap: theme.space[4],
})

const buildHistoryTitleRowCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
  alignItems: `flex-start`,
  gap: theme.space[2],
  [theme.mediaQueries.desktop]: {
    alignItems: `center`,
    flexDirection: `row`,
  },
})

const buildHistoryTitleRowNoPublishedBuildsCss: ThemeCss = theme => [
  buildHistoryTitleRowCss(theme),
  {
    flexDirection: `row`,
    [theme.mediaQueries.tablet]: {
      alignItems: `flex-start`,
    },
  },
]

const headingCss: ThemeCss = theme => ({
  whiteSpace: `nowrap`,
  fontSize: theme.fontSizes[4],
})

const topCss: ThemeCss = theme => ({
  alignItems: `end`,
  display: `grid`,
  gap: theme.space[5],
  marginTop: theme.space[5],

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr auto`,
  },
})

const buildMetaCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  gap: theme.space[2],
  alignItems: `flex-start`,
})

const siteMeta: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  flexDirection: `row`,
  flexWrap: `wrap`,

  "& > span": {
    minHeight: theme.space[7],
    marginRight: theme.space[7],
  },
})

const deploysCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  display: `inline-flex`,
  alignItems: `center`,

  svg: {
    width: `16px`,
    height: `auto`,
    marginRight: theme.space[3],
  },
})

const settingsLinkCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,

  svg: {
    fontSize: `.8em`,
    marginLeft: theme.space[2],
    color: theme.colors.grey[40],
  },

  "&:hover svg": {
    color: theme.colors.purple[40],
  },
})

const actionsCss: ThemeCss = theme => ({
  display: `inline-grid`,
  gap: theme.space[6],
  gridTemplateColumns: `auto auto`,
  justifyContent: `end`,

  a: {
    lineHeight: theme.lineHeights.solid,
  },
})

const stableBuildLink: ThemeCss = theme => [
  descriptionCss(theme),
  {
    marginTop: theme.space[7],
    justifyContent: `flex-end`,

    a: {
      fontWeight: theme.fontWeights.body,
    },
  },
]

const stableBuildLinkInline: ThemeCss = theme => [
  descriptionCss(theme),
  {
    margin: 0,
    justifyContent: `flex-end`,
    a: {
      fontWeight: theme.fontWeights.body,
    },
  },
]

const buildActionsCss: ThemeCss = theme => ({
  display: `flex`,
  alignItemes: `center`,
  flexWrap: `wrap`,
})
