import * as React from "react"
import { IntegrationChip } from "./IntegrationChip"
import { SourceControlProvider } from "@modules/graphql/types"
import {
  SourceControlProviderIcon,
  sourceControlProviderLabels,
} from "@modules/sourceControlProvider"
import { siteDetails as text } from "@modules/locales/default.js"
import { interpolateMessage } from "@modules/locales"

export type RepositoryChipProps = {
  provider: SourceControlProvider
  repositoryUrl: string
  className?: string
  "data-testid"?: string
}

export function RepositoryChip({
  provider,
  repositoryUrl,
  className,
  "data-testid": dataTestId,
}: RepositoryChipProps) {
  const label = sourceControlProviderLabels[provider]

  return (
    <IntegrationChip
      icon={<SourceControlProviderIcon sourceControlProvider={provider} />}
      href={repositoryUrl}
      label={label}
      aria-label={interpolateMessage<"provider">(
        text.labels.sourceControlProviderChip,
        { provider: label }
      )}
      className={className}
      data-testid={dataTestId}
    />
  )
}
