import { getCardBaseStyles } from "@modules/ui/stylesheets/card"

export const baseCardCss = (theme, standalone) => [
  {
    display: `grid`,
    alignItems: `center`,
    position: `relative`,
    gridTemplateColumns: `min-content 1fr auto auto`,
    gridTemplateAreas: `
      "indicator status       status  warnings detailsLink"
      "cdnStatus cdnDetail    ......  ......   ......"
      "......... event        event   event    ......"
      "......... author       author  author   ......"
      "......... metricsLabel metrics metrics  ......"
    `,
    [theme.mediaQueries.tablet]: {
      gridTemplateColumns: `min-content 1fr 2fr 1fr`,
      gridTemplateAreas: `
        "indicator status       event   warnings"
        "cdnStatus cdnDetail    author  detailsLink"
        "......... divider      divider divider"
        "......... metricsLabel metrics ......."
      `,
    },
  },
  standalone && getCardBaseStyles(theme),
]

export const statusIndicatorCellCss = theme => ({
  paddingRight: theme.space[5],
  display: `inline-flex`,
  justifyContent: `space-around`,
  gridArea: `indicator`,
  background: theme.colors.white,
  zIndex: 1,
})

export const statusIndicatorIconCss = {
  position: `relative`,
  zIndex: 1,
}

export const statusCellCss = theme => ({
  fontSize: theme.fontSizes[0],
  marginRight: theme.space[4],
  color: theme.colors.grey[60],
  gridArea: `status`,
  overflow: `hidden`,
  textOverflow: `ellipsis`,
  whiteSpace: "nowrap",
  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[1],
    overflow: `initial`,
  },
})

export const startDateCellCss = theme => ({
  fontSize: theme.fontSizes[1],
  lineHeight: theme.lineHeights.solid,
  color: theme.colors.grey[50],
  flexShrink: 0,
  marginRight: theme.space[5],
})

export const eventTextCellCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  columnGap: theme.space[3],
  fontSize: theme.fontSizes[1],
  fontWeight: 600,
  color: theme.colors.grey[70],
  gridArea: `event`,

  [theme.mediaQueries.tablet]: {
    fontWeight: "initial",
    minWidth: 0,
  },
})

export const eventTextCss = _theme => ({
  textOverflow: `ellipsis`,
  overflow: `hidden`,
  whiteSpace: `nowrap`,
  maxWidth: `80ch`,
})

export const incrementalBuildChipCss = theme => ({
  marginTop: theme.space[2],
  marginBottom: theme.space[2],
  flexShrink: 0,
  [theme.mediaQueries.tablet]: {
    marginTop: 0,
    marginBottom: 0,
  },
})

export const warningsCellCss = _theme => ({
  textAlign: `end`,
  gridArea: `warnings`,
  minHeight: "24px",
})

export const authorCellCss = theme => ({
  gridArea: `author`,
  marginBottom: `-${theme.space[2]}`,
  marginTop: theme.space[3],
  display: `grid`,
  alignItems: `center`,
  rowGap: theme.space[3],

  [theme.mediaQueries.tablet]: {
    gridTemplateColumns: `repeat(3, max-content)`,
    marginTop: 0,
  },
})

export const commitInfoCss = theme => ({
  fontSize: theme.fontSizes[0],

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[1],
  },
})

export const detailsLinkCellCss = theme => ({
  justifySelf: `end`,
  alignSelf: `start`,
  gridArea: `detailsLink`,
  marginTop: theme.space[2],
})

export const detailsLinkCss = theme => ({
  display: `none`,
  [theme.mediaQueries.tablet]: {
    display: `initial`,
  },
})

export const metricsDividerCss = theme => ({
  borderTop: `1px solid ${theme.colors.blackFade[10]}`,
  background: `none`,
  gridArea: `divider`,
  height: 0,
  margin: 0,
  paddingTop: theme.space[4],
  display: `none`,
  [theme.mediaQueries.tablet]: {
    display: `initial`,
  },
})

const lighthouseSharedCss = theme => ({
  display: `inline-flex`,
  alignItems: `center`,
  alignSelf: `stretch`,
  color: theme.colors.grey[60],
  fontSize: theme.fontSizes[0],
  lineHeight: theme.lineHeights.solid,
  // Adjust for the parent grid's bottom padding
  marginBottom: `-${theme.space[2]}`,
  marginTop: theme.space[5],
  [theme.mediaQueries.tablet]: {
    marginTop: 0,
  },
})

export const metricsLabelCellCss = theme => [
  lighthouseSharedCss(theme),
  {
    gridArea: `metricsLabel`,
    paddingRight: theme.space[3],
  },
]

export const metricsCellCss = theme => [
  lighthouseSharedCss(theme),
  {
    gridArea: `metrics`,
  },
]

export const deploymentStatusCss = theme => ({
  backgroundColor: theme.colors.white,
  zIndex: 1,
  gridArea: "cdnStatus",
  alignSelf: `start`,

  [theme.mediaQueries.tablet]: {
    paddingTop: theme.space[3],
  },
})

export const deploymentTimingCss = theme => ({
  gridArea: `cdnDetail`,
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
  alignSelf: `start`,

  [theme.mediaQueries.tablet]: {
    marginTop: theme.space[3],
  },
})
