import * as React from "react"
import { Notification, Link } from "gatsby-interface"
import { MdInfo, MdArrowForward } from "react-icons/md"
import { HostingDetails } from "@modules/graphql/types"
import { useGatsbyHostingNotifications } from "../hooks/useGatsbyHostingNotifications"
import { siteDetails as text } from "@modules/locales/default.js"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { getSiteDetailsTabBasePath } from "@modules/site/details/utils"

type ActiveAnnouncement = "noGatsbyHosting" | "noCustomDomain" | null

type Announcement = {
  message: React.ReactNode | string
  anchorText: string
}

const announcements: Record<string, Announcement> = {
  noGatsbyHosting: {
    message: text.messages.quicklyBoostYourWebPerf,
    anchorText: text.actions.getStartedNow,
  },
  noCustomDomain: {
    message: text.messages.yourSiteIsHostedOnGatsbyCloud,
    anchorText: text.actions.setUpCustomDomain,
  },
}

const announcmentLink = ({
  activeAnnouncement,
  linkToSiteSettings,
}: {
  activeAnnouncement: ActiveAnnouncement
  linkToSiteSettings: string
}) => {
  switch (activeAnnouncement) {
    default:
      return `${linkToSiteSettings}/hosting`
  }
}

export type HostingBannerProps = {
  siteId: string
  organizationId: string
  gatsbyHostingOn: boolean
  hostingDetails?: HostingDetails
}

export function HostingBanner({
  siteId,
  organizationId,
  gatsbyHostingOn,
  hostingDetails,
}: HostingBannerProps) {
  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )
  const {
    hostingNotificationVisible,
    customDomainNotificationVisible,
    hideHostingNotification,
    hideCustomDomainNotification,
  } = useGatsbyHostingNotifications(siteId)

  const [activeAnnouncement, setActiveAnnouncement] = React.useState<
    ActiveAnnouncement
  >(null)

  const [onDismiss, setOnDismiss] = React.useState<() => () => void>()

  React.useEffect(() => {
    if (!gatsbyHostingOn && hostingNotificationVisible) {
      setActiveAnnouncement(`noGatsbyHosting`)
      setOnDismiss(() => hideHostingNotification)
    } else if (
      customDomainNotificationVisible &&
      gatsbyHostingOn &&
      hostingDetails?.domains &&
      hostingDetails.domains.length === 1
    ) {
      setActiveAnnouncement(`noCustomDomain`)
      setOnDismiss(() => hideCustomDomainNotification)
    } else {
      setActiveAnnouncement(null)
    }
  }, [
    hostingNotificationVisible,
    customDomainNotificationVisible,
    gatsbyHostingOn,
    hostingDetails,
  ])

  if (!activeAnnouncement) {
    return null
  }

  return (
    <React.Fragment>
      <Notification
        Icon={MdInfo}
        variant="SECONDARY"
        showDismissButton={true}
        onDismissButtonClick={onDismiss}
        isOpened={!!activeAnnouncement}
        content={
          <React.Fragment>
            {announcements[activeAnnouncement].message} &nbsp;{" "}
            <Link
              variant="SIMPLE"
              to={announcmentLink({
                activeAnnouncement,
                linkToSiteSettings,
              })}
            >
              {announcements[activeAnnouncement].anchorText} <MdArrowForward />{" "}
            </Link>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
