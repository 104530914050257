import * as React from "react"
import Loading from "@modules/ui/components/Loading"
import {
  EmptyState,
  Link,
  Notification,
  Spacer,
  ThemeCss,
} from "gatsby-interface"
import { Feed, FeedItem } from "@modules/a11y/components/Feed"
import { BuildCard } from "@modules/build/card/components/BuildCard"
import { getPathToBuildDetails } from "@modules/site/details/utils"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { Waypoint } from "react-waypoint"
import { FormattedMessage } from "@modules/locales"
import { buildsByBranchView as buildsByBranchViewText } from "@modules/locales/default.js"
import { useBuilds } from "../hooks/useDeploys"
import { useLatestBuildByStatus } from "@modules/build/shared/hooks/useLatestBuildByStatus"
import { BuildStatus, CdnVendor } from "@modules/graphql/types"
import { useSiteDetailsQuery } from "@modules/site/shared/queries.generated"
import { useOrganizationBaseDetailsQuery } from "@modules/organization/queries.generated"
import { deploysView as deploysViewText } from "@modules/locales/default.js"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"
import { useLocalSiteDetailsForBuilds } from "@modules/site/shared/hooks/useLocalSiteDetails"
import { PublishBuildConfirmation } from "@modules/build/shared/components/PublishBuildConfirmation"
import { useRollbacksAndManualDeploysAvailable } from "@modules/organization/shared/hooks/useRollbacksAndManualDeploysAvailable"
import {
  useTriggerErrorAlert,
  ErrorAlert,
} from "@modules/ui/components/ErrorAlert"
import { BuildsFilterBar } from "@modules/site/builds/components/BuildsFilterBar"
import { useFlags } from "@modules/featureFlags"
import { useFilteredBuilds } from "@modules/site/builds/hooks/useFilteredBuilds"
import { isProperlyConnected } from "@modules/site/cdnIntegrations/helpers"
import { useCmsIntegration } from "@modules/cms/shared/hooks/useCmsIntegration"
import { isCmsConnected } from "@modules/cms/shared/utils"
import { useBuildsFilters } from "../../builds/hooks/useBuildsFilters"
import { useCurrentUser } from "@modules/auth"
import useNetPromoterSurvey from "@modules/netPromoterSurvey/useNetPromoterSurvey"
import { useWorkspaceBuildQueue } from "@modules/organization/builds/hooks/useWorkspaceBuildQueue"
import { WorkspaceBuildQueue } from "@modules/organization/builds/components/WorkspaceBuildQueue"
import { EmptyStateGraphicAlternative } from "@modules/ui/components/EmptyStateGraphicAlternative"
import { MdArrowForward, MdInfo } from "react-icons/md"

/**
 * When modifying this file, make sure you modify the PullRequestBuildsPage accordingly
 * to have the same UI for the builds by branches and builds by pull requests
 */

export type InnerBuildsListPageProps = ProductionBuildsListProps & {
  onViewBuildQueue?: (id: string) => void
}

export function InnerBuildsListPage({
  title = `Builds`,
  branch: rawBranch,
  siteId,
  organizationId,
  onViewBuildQueue,
}: InnerBuildsListPageProps) {
  const { flags } = useFlags()
  const [setError, errorAlert] = useTriggerErrorAlert()
  const [triggerBuildError] = React.useState<JSX.Element | null>(null)

  const {
    data: siteDetailsData,
    error: errorSiteDetails,
    refetch: refetchSiteDetails,
  } = useSiteDetailsQuery({
    variables: { id: siteId },
    fetchPolicy: "cache-and-network",
  })

  useSiteChangedSubscription({
    variables: { id: siteId },
  })

  // In the event that a branch name contains a `/`, the
  // routing is disturbed unless encoded (and decoded).
  const branch = decodeURIComponent(rawBranch)

  const siteDetails = siteDetailsData?.siteDetails
  const cdnIntegrations = siteDetails?.cdnIntegrations
  const connectedCdn = cdnIntegrations?.find(isProperlyConnected)?.vendor
  const sourceProvider = siteDetails?.repository?.provider

  const { cmsIntegrations } = useCmsIntegration(siteId)
  const connectedCmsIntegrations = (cmsIntegrations || []).filter(
    isCmsConnected
  )

  const siteDetailsFromCache = useLocalSiteDetailsForBuilds(siteId)
  const latestHostingDeployVersion =
    siteDetailsFromCache?.latestHostingDeployVersion
  const manualHostingDeploysEnabled =
    siteDetailsFromCache?.manualHostingDeploysEnabled

  const [buildToPublish, setBuildToPublish] = React.useState<string | null>(
    null
  )

  const repositoryUrl = siteDetails?.repository?.url

  const [loadingLastBuild] = useLatestBuildByStatus({
    siteId,
    siteBranch: branch,
    status: BuildStatus.Success,
    setError: setError,
  })

  const {
    buildStartDateFilter,
    setBuildStartDateFilter,
    buildEndDateFilter,
    setBuildEndDateFilter,
    buildStatusFilter,
    setBuildStatusFilter,
    buildTypeFilter,
    setBuildTypeFilter,
    buildKeywordFilter,
    debouncedBuildKeywordFilter,
    setBuildKeywordFilter,
    filteringIsActive,
  } = useBuildsFilters()

  const [
    filteredBuilds,
    {
      loading: filteredBuildsLoading,
      loadingMore: filteredBuildsLoadingMore,
      updating: filteredBuildsUpdating,
      loadMore: filteredBuildsLoadMore,
      error: filteredBuildsError,
    },
  ] = useFilteredBuilds({
    siteId,
    branch,
    startDate: buildStartDateFilter,
    endDate: buildEndDateFilter,
    buildStatus: buildStatusFilter,
    buildType: buildTypeFilter,
    keyword: debouncedBuildKeywordFilter,
    skip: !flags.buildsFilterBar,
  })

  const [
    allBuilds,
    cdnVendor,
    {
      loading: allBuildsLoading,
      error: allBuildsError,
      loadingMore: allBuildsLoadingMore,
      loadMore: allBuildsLoadMore,
    },
  ] = useBuilds(siteId, branch, flags.buildsFilterBar)

  const builds = flags.buildsFilterBar ? filteredBuilds : allBuilds
  const loading = flags.buildsFilterBar
    ? filteredBuildsLoading
    : allBuildsLoading
  const error = flags.buildsFilterBar ? filteredBuildsError : allBuildsError
  const loadingMore = flags.buildsFilterBar
    ? filteredBuildsLoadingMore
    : allBuildsLoadingMore
  const loadMore = flags.buildsFilterBar
    ? filteredBuildsLoadMore
    : allBuildsLoadMore

  const gatsbyHostingOn = flags.buildsFilterBar
    ? connectedCdn === CdnVendor.CloudCdn
    : cdnVendor === CdnVendor.CloudCdn

  const { data: orgData } = useOrganizationBaseDetailsQuery({
    variables: { id: organizationId },
  })

  const organization = orgData?.organizationDetails
  const isEligiblePlan =
    useRollbacksAndManualDeploysAvailable(organizationId).result

  const { currentUser } = useCurrentUser()
  useNetPromoterSurvey(organization, currentUser)

  if (loading || loadingLastBuild) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <Loading
            message={buildsByBranchViewText.messages.loadingBuilds}
            bufferSize="padded"
          />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const actualError = error || errorSiteDetails
  if (actualError) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <ErrorAlert data-testid="builds-list-page-error">
            <FormattedMessage<"error">
              message={buildsByBranchViewText.messages.errorLoadingBuilds}
              values={{ error: actualError?.message }}
            />
          </ErrorAlert>
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  if (!flags.buildsFilterBar && allBuilds.length === 0) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <EmptyState
            variant="BORDERED"
            graphic={<EmptyStateGraphicAlternative />}
            heading={buildsByBranchViewText.headers.noBuildsYet}
            text={buildsByBranchViewText.messages.noBuildHistory}
          />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  return (
    <React.Fragment>
      <PageWithTabsContentSection style={{ paddingTop: 0 }}>
        {title}
        <StandardSingleColumn id="builds" style={{ padding: `1rem 0 0 0` }}>
          <div>
            {/* TODO: refactor the way that both types of error use the same shared errorAlert */}
            {errorAlert}
            {triggerBuildError}
          </div>
          {(errorAlert || triggerBuildError) && <Spacer size={5} />}
          <h2 css={visuallyHiddenCss}>
            <FormattedMessage<"productionBranch">
              message={deploysViewText.headers.allBuildsOfBranch}
              values={{
                productionBranch: branch,
              }}
            />
          </h2>
          {flags.buildsFilterBar && (
            <React.Fragment>
              <BuildsFilterBar
                endDate={buildEndDateFilter}
                onEndDateChange={setBuildEndDateFilter}
                startDate={buildStartDateFilter}
                onStartDateChange={setBuildStartDateFilter}
                type={buildTypeFilter}
                onTypeChange={setBuildTypeFilter}
                status={buildStatusFilter}
                onStatusChange={setBuildStatusFilter}
                keyword={buildKeywordFilter}
                onKeywordChange={setBuildKeywordFilter}
                loading={filteredBuildsUpdating}
                sourceProvider={sourceProvider}
                cmsIntegrations={connectedCmsIntegrations}
              />

              <Spacer size={6} />
            </React.Fragment>
          )}
          {filteringIsActive &&
            !filteredBuildsUpdating &&
            builds?.length === 0 && (
              <EmptyState
                heading={`No matching builds`}
                text={`Sorry, no builds match your current filters.`}
                headingAs="h2"
                graphic={<EmptyStateGraphicAlternative />}
                variant="BORDERED"
              />
            )}
          {flags.buildsFilterBar && filteredBuildsUpdating ? (
            <div css={updatingCss}>
              <Loading
                variant="baby"
                message={buildsByBranchViewText.messages.loadingBuilds}
                delay={750}
              />
            </div>
          ) : (
            <Feed
              loading={loadingMore}
              labelledBy="builds"
              count={builds?.length || 0}
            >
              <div>
                {flags.disabledDataBuilds && !siteDetails?.dataBuildsEnabled ? (
                  <>
                    <Notification
                      tone="DANGER"
                      variant="SECONDARY"
                      Icon={MdInfo}
                      content={
                        <p css={contentCss}>
                          Data Update invoked builds for this site are currently
                          suspended.{" "}
                          <Link
                            variant="SIMPLE"
                            href="https://support.gatsbyjs.com/hc/en-us/articles/9905462306067-Suspended-Data-Update-Builds"
                          >
                            Docs
                            <MdArrowForward css={linkIconCss} />
                          </Link>
                        </p>
                      }
                    />
                    <Spacer size={6} />
                  </>
                ) : null}
                {builds?.map((build, index) => (
                  <div key={`build-${build.id}`}>
                    <FeedItem
                      labelledBy={`build-${build.id}`}
                      describedBy={`deploy-status-${build.id}`}
                      position={index + 1}
                      id={build.id}
                    >
                      <BuildCard
                        as="div"
                        id={`build-${build.id}`}
                        siteId={siteId}
                        organizationId={organizationId}
                        pullRequestId={build?.pullRequest?.id}
                        title={build.commit?.message || build.branch || ``}
                        buildId={build.id}
                        status={build.buildStatus}
                        createdAt={build.createdAt}
                        startedAt={build.startedAt}
                        latestHostingDeployVersion={
                          latestHostingDeployVersion ?? undefined
                        }
                        manualHostingDeploysEnabled={
                          manualHostingDeploysEnabled
                        }
                        onPublish={setBuildToPublish}
                        gatsbyHostingOn={gatsbyHostingOn}
                        isEligiblePlan={isEligiblePlan}
                        /* backend allows for null. Fallback to undefined for safe types in child components */
                        duration={build.duration || undefined}
                        endedAt={build.endedAt}
                        branch={build.branch || ``}
                        /* backend allows for null. Fallback to undefined for safe types in child components */
                        commit={build.commit || undefined}
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                        runnerType={build.runnerType!}
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                        buildType={build.buildType!}
                        /* backend allows for null. Fallback to undefined for safe types in child components */
                        source={build.source || undefined}
                        /* backend allows for null. Fallback to undefined for safe types in child components */
                        author={build.author || undefined}
                        isProductionBranch={branch === build.branch}
                        viewDetailsHref={getPathToBuildDetails(
                          build.id,
                          siteId,
                          organizationId
                        )}
                        deployStartedAt={build?.deployStartedAt}
                        deployEndedAt={build?.deployEndedAt}
                        onBuildSucceed={refetchSiteDetails}
                        repositoryUrl={repositoryUrl}
                        routeMetadata={build?.routeMetadata || undefined}
                        buildMetadata={build?.metadata || undefined}
                        onViewBuildQueue={onViewBuildQueue}
                        buildUrl={build.url}
                      />
                    </FeedItem>
                    <Spacer size={5} />
                  </div>
                ))}
              </div>
            </Feed>
          )}
          <Waypoint onEnter={loadMore} />
          <div css={loadingMoreCss}>
            {loadingMore && (
              <Loading
                variant="baby"
                message={buildsByBranchViewText.messages.loadingBuilds}
                delay={1}
              />
            )}
          </div>
        </StandardSingleColumn>
      </PageWithTabsContentSection>
      {!!buildToPublish && (
        <PublishBuildConfirmation
          siteId={siteId}
          buildId={buildToPublish}
          onDismiss={() => setBuildToPublish(null)}
          onComplete={() => {
            setBuildToPublish(null)
          }}
          manualHostingDeploysEnabled={manualHostingDeploysEnabled ?? undefined}
        />
      )}
    </React.Fragment>
  )
}

export type ProductionBuildsListProps = {
  organizationId: string
  siteId: string
  branch: string
  title: string | JSX.Element
}

/**
 * A wrapper component which renders BuildQueueComponent (modal-panel)
 * as a sibling not a child of the main component to avoid re-triggering the modal animations
 * every time the subscriptions re-render the main component (the Inner... one)
 */
export function ProductionBuildsList(props: ProductionBuildsListProps) {
  const { organizationId } = props

  // gets all stuff related to manage build queue functionality
  const buildQueue = useWorkspaceBuildQueue(organizationId)

  return (
    <React.Fragment>
      <InnerBuildsListPage onViewBuildQueue={buildQueue?.onOpen} {...props} />

      <WorkspaceBuildQueue
        organizationId={organizationId}
        isOpen={buildQueue.isOpen}
        onClose={buildQueue.onClose}
        originId={buildQueue.originId}
      />
    </React.Fragment>
  )
}

/* styles */
const loadingMoreCss: ThemeCss = theme => ({
  textAlign: "center",
  minHeight: theme.space[8],
})

const updatingCss: ThemeCss = theme => ({
  textAlign: "center",
  minHeight: theme.space[8],
  marginTop: theme.space[10],
})

const contentCss: ThemeCss = theme => ({
  margin: 0,
  fontWeight: theme.fontWeights.bold,
})

const linkIconCss: ThemeCss = theme => ({
  marginLeft: theme.space[1],
})
