import * as React from "react"

const HostingStorageKey = {
  Hosting: `hideHostingNotification`,
  CustomDomain: `showCustomDomainNotification`,
}

export function useGatsbyHostingNotifications(siteId: string) {
  const isBrowser = typeof window !== `undefined`

  const hostingNotificationKey = `${HostingStorageKey.Hosting}:${siteId}`
  const customDomainNotificationKey = `${HostingStorageKey.CustomDomain}:${siteId}`

  const hostingNotificationVisibleInitValue =
    isBrowser && window.sessionStorage.getItem(hostingNotificationKey)
      ? false
      : true

  const customDomainNotificationVisibleInitValue =
    isBrowser && window.sessionStorage.getItem(customDomainNotificationKey)
      ? false
      : true

  const [
    hostingNotificationVisible,
    setHostingNotificationVisible,
  ] = React.useState(hostingNotificationVisibleInitValue)

  const [
    customDomainNotificationVisible,
    setCustomDomainNotificationVisible,
  ] = React.useState(customDomainNotificationVisibleInitValue)

  function hideHostingNotification() {
    setHostingNotificationVisible(false)
    window.sessionStorage.setItem(hostingNotificationKey, `1`)
  }

  function hideCustomDomainNotification() {
    setCustomDomainNotificationVisible(false)
    window.sessionStorage.setItem(customDomainNotificationKey, `1`)
  }

  return {
    hostingNotificationVisible,
    customDomainNotificationVisible,
    hideHostingNotification,
    hideCustomDomainNotification,
  }
}
